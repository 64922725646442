import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./styles/style.scss";
import "./js/app"
import JQuery from 'jquery';
window.$ = JQuery;
import 'vue-good-table/dist/vue-good-table.css'
import VueGoodTablePlugin from 'vue-good-table';
import VueSimpleAccordion from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';
import Clipboard from 'v-clipboard'
import { Datetime } from 'vue-datetime';
Vue.config.productionTip = false;

Vue.component('datetime', Datetime);


Vue.use(VueSimpleAccordion, {
  // ... Options go here
});
Vue.use(Clipboard);
Vue.prototype.$hostname = 'https://adminapi.renktekal.com';

Vue.use(VueGoodTablePlugin);

new Vue({
  router,
  store,

  render: h => h(App)
}).$mount("#app");

